import { axiosPostRequest, axiosGetRequest } from './axios';
import { baseUrl } from "config";


export const getConfig = async () => {
    const response = await axiosGetRequest(`${baseUrl}/auth/config/`);
    return response;
}

export const login = async (token: string) => {
    const response = await axiosPostRequest(`${baseUrl}/auth/validate/`, {
        token: token,
    });
    return response;
}

export const refresh = async (refresh_token: string) => {
    const response = await axiosPostRequest('/token/refresh/', {
        refresh: refresh_token,
    });
    return response;
}
