import React, { useState, useEffect } from 'react'
import { Modal, Button, Accordion } from 'react-bootstrap'
import { getAllTasksRequest } from '../../api/timesheet'

function JobDetailModal({ show, handleClose }: { show: boolean, handleClose: () => void }) {
  const [job, setJob] = useState([])

  const getAllTask = async () => {
    const response = await getAllTasksRequest()
    const groupedByJob = response.data.reduce((acc: any, task: any) => {
      const jobId = task.Job.id;
      if (!acc[jobId]) {
        acc[jobId] = {
          jobDetails: task.Job,
          tasks: []
        };
      }
      acc[jobId].tasks.push(task);
      return acc;
    }, {});
    const groupedByJobArray = Object.keys(groupedByJob).map(jobId => ({
      jobDetails: groupedByJob[jobId].jobDetails,
      tasks: groupedByJob[jobId].tasks
    }));

    groupedByJobArray.sort((a, b) => {
      const statusA = a.jobDetails.JobStatus.name.toUpperCase();
      const statusB = b.jobDetails.JobStatus.name.toUpperCase();
      if (statusA < statusB) {
        return -1;
      }
      if (statusA > statusB) {
        return 1;
      }
      return 0;
    });

    setJob(groupedByJobArray)
  }

  useEffect(() => {
    getAllTask()
  }, [])


  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      fullscreen={true}
    >
      <Modal.Header
        className='bg-300'
        closeButton
      >
        <Modal.Title>Your jobs' status</Modal.Title>
      </Modal.Header>
      <Modal.Body className='bg-100'>
        <Accordion>
          {job.sort().map((job: any) => {
            let status = ""
            let bg = ""
            switch (job.jobDetails.JobStatus.name) {
              case 'Completed':
                status = "Completed"
                bg = "secondary"
                break
              case 'In Progress':
                status = "In Progress"
                bg = "success"
                break
              case 'Cancelled':
                status = "Cancelled"
                bg = "danger"
                break
              default:
                break
            }
            return (
              <Accordion.Item eventKey={job.jobDetails.id} key={job.jobDetails.id}>
                <Accordion.Header>{`${job.jobDetails.Customer.name} - ${job.jobDetails.name}`} <div className={`badge badge-soft-${bg} mx-4`}>{status}</div></Accordion.Header>
                <Accordion.Body>
                  {job.tasks.map((task: any) => (
                    <h6 key={task.id}>
                      {task.name}
                    </h6>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            )
          })}
        </Accordion>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default JobDetailModal