import React, { useContext, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import AppContext from 'context/Context';
import classNames from 'classnames';
import NavbarTop from 'components/navbar/top/NavbarTop';
import NavbarVertical from 'components/navbar/vertical/NavbarVertical';
import Footer from 'components/footer/Footer';
import ProductProvider from 'components/app/e-commerce/ProductProvider';
import CourseProvider from 'components/app/e-learning/CourseProvider';

const MainLayout = () => {
  const { hash, pathname } = useLocation();
  const isKanban = pathname.includes('kanban');
  // const isChat = pathname.includes('chat');

  const {
    config: { isFluid, navbarPosition }
  } = useContext(AppContext);

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <div className={isFluid ? 'container-fluid' : 'container-fluid'}>
        <NavbarVertical />
        <div className={classNames('content', { 'pb-0': isKanban })}>
          <NavbarTop />
          {(`${window.location.protocol}//${window.location.host}` === 'https://ams.frontend.appcentric.com.au') ? null :
            <div
              className='bg-warning text-light h6 text-center py-1 w-100'
              style={{
                // fixtop
                position: 'fixed',
                top: "4.3125rem",
                zIndex: 1000
              }}
            >This is the development environment. You need to use the production one.</div>}
          <Outlet />
        </div>
      </div>
    </>

  );
};

export default MainLayout;
