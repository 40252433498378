import React from 'react';
import CalendarManagement from 'components/dashboards/project-management/calendar/CalendarManagement';

const Scheduler = () => {
  return (
    <div>
      <CalendarManagement />
    </div>
  );
}

export default Scheduler;