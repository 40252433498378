import { createSlice } from '@reduxjs/toolkit'

interface AuthState {
    isAuth: boolean,
    username: string | null,
    csrf: string | null,
}


const initialState: AuthState = {
    isAuth: null,
    username: null,
    csrf: null,
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state, action) => {
            state.isAuth = true
            state.username = action.payload.username
        },
        logout: (state) => {
            state.isAuth = null
            state.username = null
            state.csrf = null
        },
        refresh: (state, action) => {
            state.isAuth = true
            state.username = action.payload.username
        },
        setCsrf: (state, action) => {
            state.csrf = action.payload.csrf
        },
        setIsAuth: (state, action) => {
            state.isAuth = action.payload.isAuth
        }
    },
})

export const { login, logout, refresh, setCsrf, setIsAuth } = authSlice.actions

export default authSlice.reducer