import React, { useContext, useEffect } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider
} from 'react-router-dom';
// import { toast, ToastContainer } from 'react-toastify';
import is from 'is_js';
import AppContext from 'context/Context';
// import FalconRoutes from 'routes';
// import { CloseButton } from 'components/common/Toast';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import { useDispatch } from 'react-redux'
import { setCsrf, login, setIsAuth } from './redux/auth/authSlice'
import { baseUrl } from 'config';

import MainLayout from './layouts/MainLayout';
import HomePage from './custom/pages/HomePage';
import LoginPage from './custom/pages/LoginPage';
import ErrorPage from './custom/pages/404Page';
import TimesheetPage from './custom/pages/TimesheetPage';
import { RequireAuth } from './custom/helpers/RequireAuth';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<MainLayout />}>
      <Route path="/" element={<HomePage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/timemanagement/timesheets" element={<RequireAuth><TimesheetPage /></RequireAuth>} />
      {/* <Route path="/crm/tagcontacts" element={<RequireAuth><EnrolPage /></RequireAuth>} /> */}
      <Route path="*" element={<ErrorPage />} />
    </Route>
  )
);

const App = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  const {
    config: { navbarPosition }
  } = useContext(AppContext);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
    if (is.safari()) {
      HTMLClassList.add('safari');
    }
  }, [HTMLClassList]);

  useEffect(() => {
    if (navbarPosition === 'double-top') {
      HTMLClassList.add('double-top-nav-layout');
    }
    return () => HTMLClassList.remove('double-top-nav-layout');
  }, [navbarPosition]);

  const dispatch = useDispatch()
  useEffect(() => {
    console.log("App.js: useEffect");
    const getSession = async () => {
      const res = await fetch(`${baseUrl}/auth/session/`, {
        credentials: "include",
      })
      const data = await res.json()
      const isAuthenticated = data.isAuthenticated
      const username = data.username
      let csrfToken = res.headers.get("X-CSRFToken");

      if (isAuthenticated) {
        dispatch(login({
          username: username
        }))
      }
      else {
        dispatch(setIsAuth({
          isAuth: false
        }))
      }
      dispatch(setCsrf({
        csrf: csrfToken
      }))
    }
    getSession()
  }, []);

  return (
    // <BrowserRouter basename={process.env.PUBLIC_URL}>
    //   <FalconRoutes />
    //   <ToastContainer
    //     closeButton={CloseButton}
    //     icon={false}
    //     position={toast.POSITION.BOTTOM_LEFT}
    //   />
    // </BrowserRouter>
    <RouterProvider router={router} />
  );
};

export default App;
