import { axiosPostRequest, axiosGetRequest, axiosDeleteRequest, axiosPutRequest } from './axios';


export const getTasksRequest = async () => {
    const response = await axiosGetRequest('/tasks/');
    return response;
}

export const getAllTasksRequest = async () => {
    const response = await axiosGetRequest('/tasks?type=all');
    return response;
}

export const getJobsRequest = async () => {
    const response = await axiosGetRequest('/jobs/');
    return response;
}

export const getTimesheetsRequest = async (date: string) => {
    const response = await axiosGetRequest(`/timesheets/?date=${date}`);
    return response;
}

export const postTimesheetsRequest = async (data: any) => {
    const response = await axiosPostRequest(`/timesheets/`, data);
    return response;
}

export const deleteTimesheetsRequest = async (id: string, data: any) => {
    const response = await axiosPutRequest(`/timesheets/${id}/`, data);
    return response;
}

export const putTimesheetsRequest = async (id: string, data: any) => {
    const response = await axiosPutRequest(`/timesheets/${id}/`, data);
    return response;
}


export const postWeeklyTimesheetRequest = async (data: any) => {
    const response = await axiosPostRequest('/weeklytimesheet/', data);
    return response;
}