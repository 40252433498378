import React from 'react'
import Typewriter from 'custom/Components/Typewriter';
import {
  Card,
} from 'react-bootstrap';

export default function HomePage() {
  return (
    <Card className='m-4 bg-100 h-100 w-100 mx-auto'>
      <Card.Body className='p-4 m-3'>
        <h1 className="display-4 m-3 text-appcentric pb-6">Welcome to the <Typewriter text="Appcentric Management System" delay={100} infinite /></h1>
        <h4 className='fw-bold m-3'>Please use the left hand side navigation to explore the system</h4>
      </Card.Body>
    </Card>
  )
}
