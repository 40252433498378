import React, { useEffect, useState, useRef } from 'react';
import {
  Card,
  InputGroup,
  FormControl,
  Button,
  Table,
  Form,
  Collapse,
  Tab,
  Tabs,
  Badge,
  Modal,
} from 'react-bootstrap';
import { useBlocker } from 'react-router-dom';
import Calendar from 'react-calendar';
import Select from 'react-select';
import 'react-calendar/dist/Calendar.css';
import { Toast } from '../../../../custom/helpers/index'
import ActionButton from 'components/common/ActionButton';
import IconButton from 'components/common/IconButton';
import Flex from 'components/common/Flex';
import {
  getTasksRequest,
  getJobsRequest,
  getTimesheetsRequest,
  postWeeklyTimesheetRequest,
  postTimesheetsRequest,
  putTimesheetsRequest,
} from '../../../../api/timesheet';
import moment from "moment";
import JobDetailModal from '../../../../custom/Components/JobDetailModal'
// import DoughnutChart from '../../../../custom/Components/Doughnut'


// The type of the response from the API
interface WeeklyDataProps {
  data: any,
  projected: any,
  completed: any,
}

// The type of the react-calendar date
type CalendarValue = Date | null;

// The type of the task object
type Task = {
  billed: boolean;
  customer: string;
  date: string;
  hours: number;
  job: string;
  job_id: number;
  task: string;
  task_id: number;
  entry: any[];
};

// The type of the open state of the jobs row in the weekly table view
type OpenStateType = {
  [job_name: string]: boolean;
};

// The type of the open state of the notes of each tasks in the weekly table view
type NotesOpenStateType = {
  [task_name: string]: boolean;
};

const AccpetedStatus = ['In Progress', 'Planned']

// This fucntion takes a date and returns a formatted date string in the format of 'YYYY-MM-DD'
const getFormattedDate = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const dateString = `${year}-${month}-${day}`;
  return dateString;
}

// The main timesheet component
const CalendarManagement = (): JSX.Element => {
  const [isSaving, setIsSaving] = useState(false);
  // This help function takes a date and returns an array of dates for the week that the date is in
  const getWeekDates = (selectedDay: string | number | Date) => {
    const currentDate = new Date(selectedDay);
    const currentDay = currentDate.getDay();
    const daysFromMonday = currentDay - 1;
    currentDate.setDate(currentDate.getDate() - daysFromMonday);
    const weekDates = [];
    for (let i = 0; i < 7; i++) {
      weekDates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return weekDates;
  };
  const isInitialRender = useRef(true);
  const [dirty, setDirty] = useState(false);
  // The states of the component
  // Which tab is currently active (daily or weekly)
  const [tab, setTab] = useState('weekly')
  // The selected date from the calendar (Default is today)
  const [selectedDate, setSelectedDate] = useState<CalendarValue>(new Date());
  // The dates of the week that the selected date is in
  const [weekDates, setWeekDates] = useState<Date[]>(getWeekDates(new Date()));

  // The person's jobs and tasks that are fetched from the API
  const [jobs, setJobs] = useState([])
  const [tasks, setTasks] = useState([])

  const [showJobDetails, setShowJobDetails] = useState(false)

  // The daily timesheets that are fetched from the API
  const [timesheets, setTimesheets] = useState([])
  // The weekly timesheets that are fetched from the API
  const [weeklyData, setWeeklyData] = useState<WeeklyDataProps>({
    data: {},
    projected: [],
    completed: [],
  })

  // The weekly total hours
  const [weeklyHours, setWeeklyHours] = useState(0)

  // Daily creation form State
  const [selectedJob, setSelectedJob] = useState(null)
  const [selectedTask, setSelectedTask] = useState(null)
  const [hours, setHours] = useState(1)
  const [notes, setNotes] = useState('')
  // The daily view state. (The timesheet that is being edited)
  const [editingTimesheet, setEditingTimesheet] = useState(null)

  // The open state of the jobs row in the weekly table view
  const [open, setOpen] = useState<OpenStateType>({});
  // The open state of the notes of each tasks in the weekly table view
  const [notesOpen, setNotesOpen] = useState<NotesOpenStateType>({});
  // It holds the notes that are being edited
  const [editingNotes, setEditingNotes] = useState(null)
  // It holds the timesheet that is being edited

  // The pop-up when user going to navigate away with unsaved changes
  // blocker is for react-router
  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      dirty === true &&
      currentLocation.pathname !== nextLocation.pathname
  );
  // the useEffect hook to handle the pop-up when user going to navigate away with unsaved changes
  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      if (dirty) {
        const message = 'Are you sure you want to leave?';
        event.returnValue = message; // Standard for most browsers
        return message; // For some older browsers
      }
    };
    if (dirty) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [dirty]);

  // Fetches the jobs and tasks from the API when coponent is mounted
  useEffect(() => {
    getTasks()
  }, [])

  // Fetches the timesheets from the API when the selected date is changed
  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    getTimesheets(selectedDate);
  }, [weekDates, tasks])

  // Calculates the weekly total hours when the weeklyData is changed
  useEffect(() => {
    calculateTotal()
  }, [weeklyData])

  // helper function to calculate the total hours of the weekly timesheet
  const calculateTotal = () => {
    let total = 0
    Object.keys(weeklyData.data).forEach((job: string) => {
      Object.keys(weeklyData.data[job]).forEach((task_id: string) => {
        let all_tasks = weeklyData.data[job][task_id]
        all_tasks.forEach((task: Task) => {
          for (let item of task.entry) {
            total += parseFloat(item.hours)
          }
        })
      })
    });
    weeklyData.completed.forEach((item: any) => {
      for (let data of weekDates) {
        let formatDate = moment(data).format('DD/MM/YYYY')
        let times = item[formatDate]
        if (times) {
          for (let time of times) {
            total += parseFloat(time.hours)
          }
        }
      }
    });
    setWeeklyHours(total)
  }

  // Helper function to determine is it's a weekend or not
  const isWeekend = (date: Date) => {
    return date.getDay() === 0 || date.getDay() === 6
  }
  // Resets the daily creation form
  const restDailyForm = () => {
    setSelectedJob(null)
    setSelectedTask(null)
    setHours(1)
    setNotes('')
    setEditingTimesheet(null)
  }

  const getTasks = async () => {
    try {
      const jobResponse = await getJobsRequest()
      const taskResponse = await getTasksRequest()


      let job_list = []
      let task_list = []
      for (let job of jobResponse.data) {
        job_list.push({
          label: job.name,
          value: job.id
        })
      }
      for (let task of taskResponse.data) {
        task_list.push({
          label: task.name,
          value: task.id,
          job: task.Job
        })
      }
      setTasks(task_list)
      setJobs(job_list)
    }
    catch (error) {
      Toast.fire({
        icon: 'error',
        title: 'Failed to load jobs and tasks!'
      })
    }
  }

  const getTimesheets = async (date: Date) => {
    if (!date) {
      Toast.fire({
        icon: 'error',
        title: 'Something went wrong, date missing!'
      })
      return;

    }
    const formattedDate = getFormattedDate(date)
    try {
      const timesheetResponse = await getTimesheetsRequest(formattedDate)
      setTimesheets(timesheetResponse.data['daily'])

      let d = []
      for (let date of weekDates) {
        for (let task of tasks) {
          let exist = false
          let instance = null
          for (let item of timesheetResponse.data['weekly']) {
            if (item.task === task.value) {
              exist = true
              instance = item
            }
          }
          if (exist) {
            let billed = false
            let formattedDate = moment(date).format('DD/MM/YYYY')
            for (let item of instance[formattedDate]) {
              if (item.billed) {
                billed = true
              }
            }
            if (instance[formattedDate].length > 0) {
              d.push({
                date: date.toLocaleString().split(',')[0],
                task: task.label,
                task_id: task.value,
                job_id: task.job.id,
                job: task.job.name,
                customer: task.job.Customer.name,
                billed: billed,
                entry: instance[formattedDate]
              })

            } else {
              d.push({
                date: date.toLocaleString().split(',')[0],
                task: task.label,
                task_id: task.value,
                job_id: task.job.id,
                job: task.job.name,
                customer: task.job.Customer.name,
                billed: billed,
                entry: [
                  {
                    hours: 0,
                    note: "",
                    billed: false,
                  }
                ]
              })
            }
          } else {
            d.push({
              date: date.toLocaleString().split(',')[0],
              task: task.label,
              task_id: task.value,
              job_id: task.job.id,
              job: task.job.name,
              customer: task.job.Customer.name,
              billed: false,
              entry: [
                {
                  hours: 0,
                  note: "",
                  billed: false,
                }
              ]
            })
          }
        }
      }

      let groupedTasks: any = {};

      d.forEach((task: Task) => {
        const customer = task.customer;
        const jobName = task.job;
        const taskId = task.task_id;

        const keyName = `${customer} - ${jobName}`;

        if (!groupedTasks[keyName]) {
          groupedTasks[keyName] = {};
        }

        if (groupedTasks[keyName][taskId]) {
          groupedTasks[keyName][taskId].push(task);
        } else {
          groupedTasks[keyName][taskId] = [task];
        }
      });

      let completed = []

      for (let item of timesheetResponse.data['weekly']) {
        if (!AccpetedStatus.includes(item.jobStatus)) {
          completed.push(item)
        }
      }

      setWeeklyData({
        ...weeklyData,
        data: groupedTasks,
        projected: timesheetResponse.data['projected'],
        completed: completed
      })

      if (Object.keys(open).length === 0 && open.constructor === Object) {
        let openState: any = {}
        Object.keys(groupedTasks).map((job: any) => {
          openState[job] = false
        })
        setOpen(openState)
      }

      if (Object.keys(notesOpen).length === 0 && notesOpen.constructor === Object) {
        let notesOpenState: any = {}
        Object.keys(groupedTasks).map((job: any) => {
          Object.keys(groupedTasks[job]).map((task: any) => {
            notesOpenState[task] = false
          })
        })
        setNotesOpen(notesOpenState)
      }

      Toast.fire({
        icon: 'success',
        title: 'Timesheets records loaded!'
      })
    }
    catch (error) {
      console.log(error)
      Toast.fire({
        icon: 'error',
        title: 'Timesheets records failed to load!'
      })
    }
  }

  const weeklyTimesheetSavedHandler = async () => {
    setIsSaving(true)
    try {
      await postWeeklyTimesheetRequest({
        data: JSON.stringify(weeklyData.data),
      })

      Toast.fire({
        icon: 'success',
        title: 'Timesheet record created!'
      })

      getTimesheets(selectedDate);
      setDirty(false);
    }
    catch (error) {
      Toast.fire({
        icon: 'error',
        title: `Timesheet record failed to create - ${error.response.data.message}`
      })
    }
    setIsSaving(false)
  }

  const timesheetSavedHandler = async () => {
    setIsSaving(true)
    const date = getFormattedDate(selectedDate)
    try {
      const body = {
        date: date,
        hours: hours,
        task: selectedTask?.value,
        notes: notes
      }

      await postTimesheetsRequest(body);

      Toast.fire({
        icon: 'success',
        title: 'Timesheet record created!'
      })

      getTimesheets(selectedDate)
      restDailyForm()
    }
    catch (error) {
      Toast.fire({
        icon: 'error',
        title: `Timesheet record failed to create - ${error.response.data.message}`
      })
    }
    setIsSaving(false)
  }

  const timesheetDeleteHandler = async (id: string, task: string) => {
    setIsSaving(true)
    try {
      const body = {
        hours: 0,
        task: task,
      }
      await putTimesheetsRequest(id, body)

      Toast.fire({
        icon: 'success',
        title: 'Timesheet record deleted!'
      })
      getTimesheets(selectedDate)
      restDailyForm()
    }
    catch (error) {
      Toast.fire({
        icon: 'error',
        title: `Timesheet record failed to delete - ${error.response.data.message}`
      })
    }
    setIsSaving(false)
  }

  const timesheetUpdateHandler = async (id: string) => {
    setIsSaving(true)
    try {
      const body = {
        hours: hours,
        task: selectedTask?.value,
        notes: notes
      }
      await putTimesheetsRequest(id, body)

      Toast.fire({
        icon: 'success',
        title: 'Timesheet record updated!'
      })

      await getTimesheets(selectedDate)
    }
    catch (error) {
      Toast.fire({
        icon: 'error',
        title: `Timesheet record failed to updated - ${error.response.data.message}`
      })
    }
    setIsSaving(false)
  }

  return (
    <>
      <Modal show={blocker.state === "blocked"} centered>
        <Modal.Header className='bg-100'>
          <div className='h4'>Are you sure you want to leave?</div>
        </Modal.Header>
        <Modal.Body>
          <div className='h-6 mb-3'>There's some unsaved changes.</div>
          <div className='d-flex justify-content-end align-items-center'>
            <Button
              className='mx-1'
              variant="danger"
              onClick={() => blocker.reset()}
            >
              Cancel
            </Button>
            <Button
              variant="success"
              onClick={() => blocker.proceed()}
            >
              Proceed
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Card className="h-100">
        <Card.Body className="p-0 m-4">
          <Flex
            justifyContent="start"
            alignItems="start"
            alignContent=""
            inline={false}
            wrap=""
            className="mb-3"
            breakpoint=""
            direction=""
          >
            <div className='w-50'>
              <h5 className="font-weight-bold mb-4">Calendar</h5>
              <Calendar
                onChange={(e) => {
                  setSelectedDate(e as Date)
                  setWeekDates(getWeekDates(e as Date));
                }}
                value={selectedDate}
              />
            </div>
            <div>
              <h5 className="font-weight-bold mb-1">Records</h5>
              {timesheets.length === 0 ? <h6 className='mb-4'>No records found on {selectedDate.toLocaleString().split(',')[0]}</h6> :
                <Table responsive className='mb-4'>
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Job</th>
                      <th scope="col">Task</th>
                      <th scope="col">Hours</th>
                      <th className="text-end" scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {timesheets.map((timesheet, index) => {
                      if (timesheet.hours == 0) return null
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{timesheet.Task.Job.name}</td>
                          <td>{timesheet.Task.name}</td>
                          <td>{timesheet.hours}</td>
                          <td className="text-end">
                            <ActionButton
                              icon="edit"
                              title="Edit"
                              variant="action"
                              className="p-0 me-2"
                              disabled={timesheet.billed || !AccpetedStatus.includes(timesheet.Task.Job.JobStatus.name)}
                              onClick={() => {
                                setHours(timesheet.hours)
                                setNotes(timesheet.note ?? "")
                                jobs.filter((job) => job.value === timesheet.Task.Job.id).map((job) => setSelectedJob(job))
                                tasks.filter((task) => task.value === timesheet.Task.id).map((task) => setSelectedTask(task))
                                setEditingTimesheet(timesheet)
                                setTab('daily')
                              }}
                            />
                            <ActionButton
                              icon="trash-alt"
                              title="Delete"
                              variant="action"
                              className="p-0"
                              disabled={timesheet.billed || !AccpetedStatus.includes(timesheet.Task.Job.JobStatus.name)}
                              onClick={() => timesheetDeleteHandler(timesheet.id, timesheet.Task.id)}
                            />
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>}
              <h5 className="font-weight-bold mb-1">Projected</h5>
              {weeklyData.projected.filter((item: any) => {
                let parts = selectedDate.toLocaleString().split(',')[0].split(/[\s/:-]+/);
                let date = new Date(`${parts[2]}-${parts[1]}-${parts[0]}`.replace(/-/g, "/")).toISOString().split('T')[0];
                return item.recorded === date
              }
              ).length === 0 ? <h6 className='mb-4'>No projected hours found on {selectedDate.toLocaleString().split(',')[0]}</h6> :
                <Table responsive className='mb-4'>
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Job</th>
                      <th scope="col">Task</th>
                      <th scope="col">Hours</th>
                    </tr>
                  </thead>
                  <tbody>
                    {weeklyData.projected.filter((item: any) => {
                      let parts = selectedDate.toLocaleString().split(',')[0].split(/[\s/:-]+/);
                      let date = new Date(`${parts[2]}-${parts[1]}-${parts[0]}`.replace(/-/g, "/")).toISOString().split('T')[0];
                      return item.recorded === date
                    }
                    ).map((projected: any, index: any) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{projected.Task.Job.name}</td>
                          <td>{projected.Task.name}</td>
                          <td>{projected.hours}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>}
            </div>
          </Flex>
          <div className='d-flex justify-content-between align-items-center'>
            {tab === 'daily' ?
              <h5 className="font-weight-bold my-4">Your are currently editing {selectedDate.toLocaleString().split(',')[0]}</h5> :
              <h5 className="font-weight-bold my-4">Your are currently editing {weekDates[0].toLocaleString().split(',')[0]} - {weekDates[weekDates.length - 1].toLocaleString().split(',')[0]}</h5>
            }
            <Button
              size={"sm"}
              className="btn-appcentric"
              onClick={() => setShowJobDetails(true)}
            >
              Show your Jobs Status
            </Button>
            <JobDetailModal
              handleClose={() => setShowJobDetails(false)}
              show={showJobDetails}
            />
          </div>
          <Tabs
            defaultActiveKey="weekly"
            id="uncontrolled-tab-example"
            className="mb-3"
            activeKey={tab}
            onSelect={
              (tab) => {
                setTab(tab)
              }}
          >
            <Tab
              eventKey="daily"
              title="Daily"
            >
              <div style={{ overflow: "visible" }}>
                {editingTimesheet ?
                  <div>
                    <Button
                      size={"sm"}
                      className="float-end"
                      onClick={() => {
                        setEditingTimesheet(null)
                        restDailyForm()
                      }}>
                      Create new timesheet
                    </Button>
                    <h6>Editing {editingTimesheet.Task.name} - {editingTimesheet.Task.Job.name}</h6>
                  </div> :
                  <h6>Creating new timesheet</h6>
                }
                <label>Job</label>
                <Select
                  placeholder={'Select Job'}
                  className="basic-single mb-3"
                  classNamePrefix="select"
                  isSearchable={true}
                  name="Job"
                  options={jobs}
                  onChange={(e) => {
                    setSelectedJob(e)
                    setSelectedTask(null)
                  }}
                  value={selectedJob || null}
                  styles={{
                    menu: provided => ({ ...provided, zIndex: 9999 }),
                    menuList: provided => ({ ...provided, zIndex: 9999 }),
                  }}
                />
                <label>Task</label>
                <Select
                  placeholder={'Select Task'}
                  className="basic-single mb-3"
                  classNamePrefix="select"
                  isSearchable={true}
                  name="Task"
                  options={tasks.filter((task) => task.job.id === selectedJob?.value)}
                  onChange={(e) => {
                    setSelectedTask(e)
                    timesheets.filter((timesheet) => timesheet.Task.id === e.value).map((timesheet) => {
                      setEditingTimesheet(timesheet)
                      setHours(timesheet.hours)
                      setNotes(timesheet.note ?? "")
                    })
                  }}
                  isDisabled={!selectedJob}
                  value={selectedTask || null}
                  styles={{
                    menu: provided => ({ ...provided, zIndex: 999999999 }),
                    menuList: provided => ({ ...provided, zIndex: 99999999 }),
                  }}
                />
                <InputGroup className="mb-4">
                  <InputGroup.Text id="inputGroup-sizing-hour">Hours</InputGroup.Text>
                  <FormControl
                    aria-label="hour"
                    aria-describedby="inputGroup-sizing-hour"
                    type="number"
                    value={hours}
                    onChange={(e) => setHours(Number(e.target.value))}
                  />
                </InputGroup>
                <Form.Group className="mb-3" controlId="noteForm.ControlTextarea1">
                  <Form.Label>Notes</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Write some notes here..."
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                  />
                </Form.Group>
                {editingTimesheet ?
                  <Button variant='outline-info float-end' className='me-2 mb-1' onClick={() => timesheetUpdateHandler(editingTimesheet.id)} disabled={isSaving}>Update</Button> :
                  <Button variant='outline-success float-end' className='me-2 mb-1' onClick={timesheetSavedHandler} disabled={isSaving}>Create</Button>}
              </div>

            </Tab>
            <Tab
              eventKey="weekly"
              title="Weekly"
            >
              {weeklyData &&
                <>
                  <Flex
                    justifyContent="between"
                    alignItems="end"
                    alignContent=""
                    inline={false}
                    wrap=""
                    className="mb-3"
                    breakpoint=""
                    direction=""
                  >
                    <Card className='w-25 p-0 bg-200'>
                      <Card.Body>
                        <h6>Weekly Total</h6>
                        <h1 className="display-4 mb-3 text-appcentric">{weeklyHours} hrs</h1>
                      </Card.Body>
                    </Card>
                    {/* <DoughnutChart weeklyData={weeklyData} /> */}
                    <div>
                      <IconButton
                        className="me-2 mb-1"
                        variant="falcon-default"
                        size="sm"
                        icon="plus"
                        iconClassName=""
                        transform="shrink-3"
                        onClick={() => {
                          let temp = { ...open }
                          Object.keys(temp).forEach((job: any) => {
                            temp[job] = true
                          })
                          setOpen(temp)
                        }}
                      >
                        Expand All
                      </IconButton>
                      <IconButton
                        className="me-2 mb-1"
                        variant="falcon-default"
                        size="sm"
                        icon="minus"
                        iconClassName=""
                        transform="shrink-3"
                        onClick={() => {
                          let temp = { ...open }
                          Object.keys(temp).forEach((job: any) => {
                            temp[job] = false
                          })
                          let temp2 = { ...notesOpen }
                          Object.keys(temp2).forEach((task: any) => {
                            temp2[task] = false
                          })
                          setNotesOpen(temp2)
                          setOpen(temp)
                        }}
                      >
                        Collapse All
                      </IconButton>
                      <Button
                        variant={dirty ? 'outline-danger' : 'outline-success'}
                        onClick={() => {
                          weeklyTimesheetSavedHandler()
                        }}
                        disabled={isSaving}
                      >
                        Save
                      </Button>
                    </div>
                  </Flex>
                  <Table bordered>
                    <thead>
                      <tr>
                        <th>Task</th>
                        {weekDates.map((date, index) => {
                          if (isWeekend(date)) {
                            return (
                              <th key={index} className='bg-soft-danger'>{date.toLocaleString().split(',')[0]}</th>
                            )
                          }
                          return (
                            <th key={index}>{date.toLocaleString().split(',')[0]}</th>
                          )
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(weeklyData.data).sort().map((job: any) => {
                        return (
                          <React.Fragment key={job}>
                            <tr
                              className='bg-200 border border-3'
                              onClick={() => {
                                let copiedOpenState = { ...open }
                                copiedOpenState[job] = !open[job]
                                setOpen(copiedOpenState)

                                let copiedNotesOpenState = { ...notesOpen }
                                Object.keys(copiedNotesOpenState).forEach((task: any) => {
                                  // FIXME
                                  copiedNotesOpenState[task] = false
                                })
                                setNotesOpen(copiedNotesOpenState)
                              }}
                            >
                              <td colSpan={8}>{job}</td>
                            </tr>
                            {Object.keys(weeklyData.data[job]).map((task: any, idx) => {
                              let taskId = weeklyData.data[job][task][0].task_id
                              return (
                                <React.Fragment key={`${idx}`}>
                                  <Collapse in={open[job]}>
                                    <tr>
                                      <td>{weeklyData.data[job][task][0].task}</td>
                                      {weekDates.map((date, index) => {
                                        let instance: any = null;
                                        let i: any = null;
                                        weeklyData.data[job][task].forEach((item: any, idx: any) => {
                                          if (item.date === date.toLocaleString().split(',')[0]) {
                                            instance = item
                                            i = idx
                                          }
                                        });
                                        if (!instance) {
                                          return <td key={index}>...</td>
                                        }
                                        return (
                                          <td key={index} className={isWeekend(date) ? 'bg-soft-danger' : ''}>
                                            {instance.entry.map((item: any, i: any) => {
                                              let parts = instance.date.split(/[\s/:-]+/);
                                              let date = new Date(`${parts[2]}-${parts[1]}-${parts[0]}`.replace(/-/g, "/")).toISOString().split('T')[0];
                                              let taskId = instance.task_id

                                              let projected = weeklyData.projected.filter((item: any) => {
                                                return item.recorded === date && item.Task.id === taskId
                                              })
                                              let projectedHours = null
                                              if (projected.length > 0) {
                                                projectedHours = projected[0].hours
                                              }
                                              let hours = parseFloat(item.hours)
                                              return (
                                                <React.Fragment key={i}>
                                                  <Form.Control
                                                    className={hours !== 0 ? (item.billed ? "mb-1" : "mb-1 bg-soft-success") : "mb-1"}
                                                    disabled={item.billed}
                                                    type="number"
                                                    value={hours}
                                                    min={0}
                                                    max={24}
                                                    step={0.5}
                                                    onChange={(e) => {
                                                      let temp = { ...weeklyData }
                                                      temp.data[job][task][index].entry[i].hours = Number(e.target.value)
                                                      setWeeklyData(temp)
                                                      setDirty(true)
                                                    }}
                                                    onClick={() => {
                                                      let temp = { ...notesOpen }
                                                      // set the notes open state to true, other to false
                                                      Object.keys(temp).forEach((task: any) => {
                                                        if (task == taskId) {
                                                          temp[task] = true
                                                        } else {
                                                          temp[task] = false
                                                        }
                                                      })
                                                      setNotesOpen(temp)
                                                      setEditingNotes({
                                                        note: instance.entry[i].note,
                                                        task: instance.task,
                                                        date: instance.date,
                                                        i: i,
                                                        idx: index,
                                                      })
                                                    }}
                                                  />
                                                  <Badge pill className='bg-appcentric text-light'>
                                                    {projectedHours}
                                                  </Badge>
                                                </React.Fragment>
                                              )
                                            })}
                                          </td>
                                        )
                                      })}
                                    </tr>
                                  </Collapse>
                                  <Collapse in={notesOpen[taskId]}>
                                    <tr>
                                      <td></td>
                                      <td colSpan={8}>
                                        <Form.Group className="mb-3">
                                          <Form.Label>Notes for {weeklyData.data[job][task][0].task} - {editingNotes?.date}</Form.Label>
                                          <Form.Control
                                            as="textarea"
                                            rows={3}
                                            value={editingNotes?.note ?? ""}
                                            onChange={(e) => {
                                              let temp = { ...weeklyData }
                                              temp.data[job][task][editingNotes.idx].entry[editingNotes.i].note = e.target.value
                                              setEditingNotes({ ...editingNotes, note: e.target.value })
                                              setWeeklyData(temp)
                                            }}
                                          />
                                        </Form.Group>
                                      </td>
                                    </tr>
                                  </Collapse>
                                </React.Fragment>
                              )
                            })}
                          </React.Fragment>
                        )
                      })}
                      {weeklyData.completed.length > 0 &&
                        <>
                          <tr className='bg-400 text-200'>
                            <td colSpan={8} className=''>Completed / Closed Projects</td>
                          </tr>
                          {
                            weeklyData.completed.map((item: any, index: any) => {
                              return (
                                <tr key={index} className='bg-100 text-400'>
                                  <td>{item.job} - {item.taskName}</td>
                                  {weekDates.map((date, index) => {
                                    let formatDate = moment(date).format('DD/MM/YYYY')
                                    let times = item[formatDate]
                                    let hours = 0
                                    if (times) {
                                      for (let time of times) {
                                        hours += parseFloat(time.hours)
                                      }
                                    }

                                    if (isWeekend(date)) {
                                      return (
                                        <td key={index}>{hours}</td>
                                      )
                                    }
                                    return (
                                      <td key={index}>{hours}</td>
                                    )
                                  })}
                                </tr>
                              )
                            })
                          }
                        </>
                      }

                      <tr className='fw-bold bg-appcentric text-light'>
                        <td >Total</td>
                        {weekDates.map((d, index) => {
                          let totalHours = 0;
                          Object.keys(weeklyData.data).forEach((job: any) => {
                            Object.keys(weeklyData.data[job]).forEach((tasks: any) => {
                              let all_tasks = weeklyData.data[job][tasks]
                              let current_date = d.toLocaleString().split(',')[0]
                              all_tasks.forEach((task: any) => {
                                if (task.date === current_date) {
                                  for (let item of task.entry) {
                                    totalHours += parseFloat(item.hours)
                                  }
                                }
                              })
                            });
                          });
                          weeklyData.completed.forEach((item: any) => {
                            let formatDate = moment(d).format('DD/MM/YYYY')
                            let times = item[formatDate]
                            if (times) {
                              for (let time of times) {
                                totalHours += parseFloat(time.hours)
                              }
                            }
                          });
                          return (
                            <td key={index}>{totalHours}</td>
                          )
                        })}
                      </tr>
                    </tbody>
                  </Table>
                </>}
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>
    </>
  );
};


export default CalendarManagement;