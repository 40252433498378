import axios from "axios";
import { apiUrl } from "config";
import { RootState, store } from '../redux/store';

const _axios = axios.create({
    withCredentials: true,
    baseURL: apiUrl,
    headers: {
        "Content-Type": "application/x-www-form-urlencoded"
    },
});

// So we listen to the redux store and update the access token in the axios headers
// It's a bit of a hacky, but it works. (Refator this later if there's more elegant solution)
store.subscribe(listener)

function select(state: RootState) {
    return state.auth.csrf;
}

function listener() {
    let csrf_token = select(store.getState())
    _axios.defaults.headers.common['X-CSRFToken'] = csrf_token;
}

// The generic axios GET request
export const axiosGetRequest = async (url: string) => {
    const response = await _axios.get(url);
    return response;
}

// The generic axios POST request
export const axiosPostRequest = async (url: string, data: any) => {
    const response = await _axios.post(url, data);
    return response;
}
// The generic axios DELETE request
export const axiosDeleteRequest = async (url: string, data: any) => {
    const response = await _axios.delete(url, data);
    return response;
}
// The generic axios PUT request
export const axiosPutRequest = async (url: string, data: any) => {
    const response = await _axios.put(url, data);
    return response;
}


export default _axios;