import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import Main from './Main';
import 'helpers/initFA';
import 'index.css';
import { store } from './redux/store'
import { Provider } from 'react-redux'
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { getConfig } from './api/auth';


const Index = () => {

  const [config, setConfig] = useState(null)
  const [error, setError] = useState(false)

  useEffect(() => {
    const setupConfig = async () => {
      try {
        const response = await getConfig();
        const data = response.data;
        setConfig({
          auth: {
            clientId: data.clientId, // Replace with your client ID
            authority: data.authority, // Replace with your tenant ID
            redirectUri: `${window.location.protocol}//${window.location.host}`
          }
        });
      }
      catch (error) {
        console.log(error)
        setError(true)
      }
    };
    setupConfig();
  }, []);

  if (!config && !error) return <div>Loading config...</div>;
  if (error) return <div>Failed to load config... Please reach out someone to have a look on it!</div>


  const msalInstance = new PublicClientApplication(config);
  return (
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <Main>
          <App />
        </Main>
      </Provider>
    </MsalProvider>
  );

}


const container = document.getElementById('main');
const root = createRoot(container);

root.render(<Index />);
