export const version = '4.2.0';
export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg';
export const settings = {
  isFluid: false,
  isRTL: false,
  isDark: false,
  navbarPosition: 'vertical',
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: '$',
  isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
  navbarStyle: 'transparent'
};

const envSettings = window;

export const baseUrl = process.env.REACT_APP_BASE_URL ?? envSettings.REACT_APP_BASE_URL ?? '';
export const apiUrl = process.env.REACT_APP_API_URL ?? envSettings.REACT_APP_API_URL ?? '';
export const authUrl = process.env.REACT_APP_AUTH_URL ?? envSettings.REACT_APP_AUTH_URL ?? '';
export const client_id = process.env.REACT_APP_CLIENT_ID ?? envSettings.REACT_APP_CLIENT_ID ?? '';
export const client_secret = process.env.REACT_APP_CLIENT_SECRET ?? envSettings.REACT_APP_CLIENT_SECRET ?? '';



export default {
  version,
  navbarBreakPoint,
  topNavbarBreakpoint,
  settings,
  apiUrl
};

