import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { login, setCsrf } from '../../redux/auth/authSlice'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Toast } from '../helpers/index';
import { useMsal } from '@azure/msal-react';
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store';
import { Card, Col, Row } from 'react-bootstrap';
import Flex from '../../components/common/Flex';
import Section from '../../components/common/Section';
import { baseUrl} from '../../config';


export default function LoginPage() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    let nextPage = searchParams.get('next');
    let auto_redirect = searchParams.get('auto_redirect');
    const dispatch = useDispatch()
    const { instance } = useMsal();
    const isAuth = useSelector((state: RootState) => state.auth.isAuth);
    // const csrf_token = useSelector((state: RootState) => state.auth.csrf);
    useEffect(() => {
      console.log(isAuth)
      if (isAuth) {
          navigate(nextPage ? nextPage : '/');
      }
      else{
          if(auto_redirect === "True" && isAuth === false){
              initializeSignIn();
          }
        }
    }, [isAuth]);
    const initializeSignIn = async () => {
        try {
            const ms_response = await instance.loginPopup();
            const response = await fetch(`${baseUrl}/auth/login?token=${ms_response.idToken}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
            })
            if (response.ok) {
                const data = await response.json();
                const res = await fetch(`${baseUrl}/auth/session/`, {
                  credentials: "include",
                })
                const data1 = await res.json()
                const isAuthenticated = data1.isAuthenticated
                const username = data1.username
                let csrfToken = res.headers.get("X-CSRFToken");

                if (isAuthenticated) {
                  dispatch(login({
                    username: username
                  }))
                }
                dispatch(setCsrf({
                  csrf: csrfToken
                }))
                Toast.fire({
                    icon: 'success',
                    title: 'Signed in Successfully'
                })
                navigate(nextPage ? nextPage : '/');
            }
          else{
            Toast.fire({
                icon: 'error',
                title: 'Signed in Failed'
            })
          }
        } catch (error) {
            console.error(error);
            Toast.fire({
                icon: 'error',
                title: 'Signed in Failed'
            })
        }
    };

    return (
    <Section fluid className="py-0" bg="" image="" overlay="" position="" video={[]} bgClassName="">
      <Row className="g-0 min-vh-100 flex-center">
        <Col lg={8} xxl={5} className="py-3 position-relative">
          <img
            className="bg-auth-circle-shape"
            alt=""
            width="250"
          />
          <img
            className="bg-auth-circle-shape-2"
            alt=""
            width="150"
          />
          <Card className="overflow-hidden z-index-1">
            <Card.Body className="p-0">
              <Row className="h-100 g-0">
                <Col md={5} className="text-white text-center bg-appcentric">
                  <div className="position-relative p-4 pt-md-5 pb-md-7">
                    <div className="z-index-1 position-relative light">
                      <p
                        className="link-light mb-4 font-sans-serif fw-bolder fs-4 d-inline-block"
                      >
                        Login
                      </p>
                      <p className="opacity-75 text-white">
                        Please login with your Appcentric Account to continue.
                      </p>
                    </div>
                  </div>
                  <div className="mt-3 mb-4 mt-md-4 mb-md-5 light">

                  </div>
                </Col>
                <Col
                  md={7}
                  as={Flex}
                  alignItems="center"
                  justifyContent="center"
                >
                  <div className="p-4 p-md-5 flex-grow-1">
                    <button className="btn btn-outline-secondary" onClick={initializeSignIn}>Sign in with your Appcentric Account</button>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Section>
    )
}
