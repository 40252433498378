import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import React, { ReactNode } from "react";
import { RootState } from '../../redux/store';

interface Props {
    children?: JSX.Element
}

export const RequireAuth = ({ children }: Props) => {
    const isLogin = useSelector((state: RootState) => state.auth.isAuth);
    const location = useLocation();

    if (!isLogin) {
        return <Navigate to={`/login?next=${location.pathname}`} />;
    }
    return children;
};
