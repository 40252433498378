export const financeRoutes = {
  // label: 'XERO',
  // children: [
  //   {
  //     name: 'Payslips',
  //     icon: 'file',
  //     to: '/payroll/payslips',
  //     active: true
  //   },
  //   {
  //     name: 'Expense Claims',
  //     icon: 'star',
  //     to: '/payroll/expenses',
  //     active: true
  //   },
  //   {
  //     name: 'Leave Applications',
  //     icon: 'star',
  //     to: '/payroll/leaves',
  //     active: true
  //   },
  // ],
};

export const timeManagementRoutes = {
  label: 'Time Management',
  children: [
    {
      name: 'Time Sheets',
      icon: 'clock',
      to: '/timemanagement/timesheets',
      active: true
    },
  ],
};

export const crmRoutes = {
  // label: 'Hubspot',
  // children: [
  //   {
  //     name: 'Tag Contacts',
  //     icon: 'map',
  //     to: '/crm/tagcontacts',
  //     active: true
  //   },
  // ],
};


export default [
  timeManagementRoutes,
];
