import React from 'react'
import {
    Card,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function ErrorPage() {
    return (
        <Card className='w-25 m-4 bg-100 h-100 w-75'>
            <Card.Body className='p-4 m-3'>
                <h1 className="display-4 m-3 text-appcentric mb-6">Opps. Page not found!</h1>
                <h4 className='fw-bold m-3'>You've enter the wrong URL or the page is still under development.</h4>
                <h4 className='fw-bold m-3'>Please explore other pages first and maybe come back later</h4>
                <Link className='m-3 btn btn-appcentric' to="/" >Go back to homepage</Link>
            </Card.Body>
        </Card>
    )
}
