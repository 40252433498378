import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppContext from 'context/Context';
import React, { useContext } from 'react';
import { Link } from "react-router-dom";
import { Nav, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';

import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../../redux/auth/authSlice';
import { Toast } from '../../../custom/helpers/index';
import { baseUrl } from 'config';



const TopNavRightSideNavItem = () => {
  const {
    config: { isDark, isRTL },
    setConfig
  } = useContext(AppContext);

  const isLogin = useSelector((state) => state.auth.isAuth);
  const username = useSelector((state) => state.auth.username);
  const dispatch = useDispatch();
  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center"
      as="ul"
    >
      {username &&
        <div className='bg-300 py-2 px-4 rounded-3'>
          Hello, {username}
        </div>}
      <div className='mx-3'>{
        isLogin === true ?
          <Link
            className='text-danger'
            onClick={async () => {
              const response = await fetch(`${baseUrl}/auth/logout/`, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                },
                credentials: "include",
              })
              if (response.ok) {
                dispatch(logout())
                Toast.fire({
                  icon: 'success',
                  title: 'Logout Successfully'
                })
              }
              else {
                Toast.fire({
                  icon: 'error',
                  title: 'Logout Failed'
                })
              }
            }}
          >
            Logout
          </Link>
          :
          <Link className='text-appcentric' to="/login">
            Login
          </Link>
      }
      </div>
      <Nav.Item as={'li'}>
        <Nav.Link
          className="px-2 theme-control-toggle"
          onClick={() => setConfig('isDark', !isDark)}
        >
          <OverlayTrigger
            key="left"
            placement={isRTL ? 'bottom' : 'left'}
            overlay={
              <Tooltip style={{ position: 'fixed' }} id="ThemeColor">
                {isDark ? 'Switch to light theme' : 'Switch to dark theme'}
              </Tooltip>
            }
          >
            <div className="theme-control-toggle-label">
              <FontAwesomeIcon
                icon={isDark ? 'sun' : 'moon'}
                className="fs-0"
              />
            </div>
          </OverlayTrigger>
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
};

export default TopNavRightSideNavItem;
